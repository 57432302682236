<template>
  <b-container>
    <b-row>
      <b-col cols="1" />
      <b-col>
        <h2>Bike fotografieren</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <p>Bitte halte den Status des Bikes fest</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <!-- shows the webcam frame -->
        <vue-web-cam ref="webcam" class="webCamImg" width="100%" style="width: 100%; height: 100%" @cameras="onCameras" :device-id="deviceId" :selectFirstDevice="true"/>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row v-show="devices.length > 1">
      <b-col cols="1" />
      <b-col cols="10">
        <!-- button to switch cameras if there are multiple to choose from -->
        <div style="cursor: pointer; text-align: right;" @click="changeCam">
          <b-icon icon="arrow-repeat"/>
          Kamera wechseln
        </div>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <!-- captures image -->
        <b-button class="buttonSpacing" variant="primary" @click="onCapture">Bild aufnehmen</b-button>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <img :src="img" class="img-responsive" />
  </b-container>
</template>

<script>
  export default {
    name: 'Camera',
    data() {
      return {
        devices: [],
        deviceId: null,
        cameraPos: 0,
        img: null,
      };
    },
    computed: {
    },
    watch: {
        devices: function() {
          // updates the device if a new one gets connected
          this.deviceId = this.devices[this.cameraPos].deviceId;
        }
    },
    mounted() {
      setTimeout(() => {
        this.$refs.webcam.start();
      }, 100000);
    },
    methods: {
      onCameras(cameras) {
        // gets called when camera gets connected
        this.devices = cameras;
        console.log("On Cameras Event", cameras);
      },
      changeCam() {
        // switches camera
        this.cameraPos < this.devices.length - 1 ? this.cameraPos += 1 : this.cameraPos = 0;
        this.deviceId = this.devices[this.cameraPos].deviceId;
      },
      onCapture() {
        // gets called when image gets captured
        this.img = this.$refs.webcam.capture();
        this.$router.push({name: 'CameraCapture', params: { img: this.img} })
      },
    }
  };
</script>